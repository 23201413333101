<template>
  <div class="verifycenter">
    <el-form ref="verifyloginForm" :model="verifyloginForm" :rules="rules">
      <el-form-item prop="phone">
        <el-row :gutter="17">
          <el-col :span="17">
            <el-input
              v-model="verifyloginForm.mobile"
              placeholder="请输入您的手机号"
            >
              <span
                slot="prefix"
                class="iconfont icon-shouji loginiconfont"
              ></span>
            </el-input>
          </el-col>
          <el-col :span="7">
            <verfiy-btn @sendKey = "getKey"  :mobile="verifyloginForm.mobile"></verfiy-btn>
            <!-- <el-button type="primary" @click="getCode">获取验证码</el-button> -->
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item prop="verify">
        <el-input
          v-model="verifyloginForm.code"
          placeholder="请输入验证码"
        >
          <span slot="prefix" class="iconfont icon-mima loginiconfont"></span>
        </el-input>
      </el-form-item>
      <!-- <el-form-item style="margin-top: -15px">
        <el-col :span="5">
          <el-checkbox label="自动登录"></el-checkbox>
        </el-col>
      </el-form-item> -->
    </el-form>
    <div v-if="isBinding">
      <el-button @click="submitForm('verifyloginForm')" size="small" type="primary">立即绑定</el-button>
    </div>
  </div>
</template>
<script>
import { getMobileCode, checkMobileInSys } from "@/api/user";
import VerfiyBtn from '@/components/verfiyBtn/verfiyBtn';

export default {
  name: "VerifyCenter",
  components: { VerfiyBtn },
  data() {
    return {
      verifyloginForm: {
        mobile: "",
        code: "",
      },
      rules: {
        mobile: [
          { required: true, message: "请输入您的手机号码", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      checkKey: null,
    };
  },
  props: {
    isBinding: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getKey(key) {
      this.checkKey = key;
    },
    // 获取手机验证码
    getCode() {
      // 校验手机号码是否为空
      if (this.verifyloginForm.mobile == "") {
        this.$message.error("请输入手机号码！");
        return false;
      }
      this.checkMobileInSys(this.verifyloginForm.mobile).then((boo) => {
        if (boo) {
          getMobileCode(this.verifyloginForm.mobile).then((res) => {
            if (res.success) {
              this.checkKey = res.data;
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          // 提示系统内不存在该用户，转去注册
          this.$message({
            type: 'warning',
            message: '系统内不存在该用户，请先注册！'
          })
          setTimeout(() => {
            this.$router.push({
              name: "RoleChoose",
            });
          }, 3000);
        }
      });
    },
    // 校验手机是否有账号
    checkMobileInSys(mobile) {
      return new Promise((resolve) => {
        checkMobileInSys(mobile).then((res) => {
          if (res.success) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },
    // 表单提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let verifyloginForm = this.deepClone(this.verifyloginForm);
          verifyloginForm.checkKey = this.checkKey;
          // 校验成功，返回登录信息
          this.$emit("validateSuccess", verifyloginForm);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.huoqu {
  height: 40px;
  padding: 0 16px;
  color: #fff;
}
.el-input:focus-within {
  span {
    color: #ff5b0c;
  }
}
</style>