<template>
  <div id="wechatlogin">
    <div class="wrap">
      <logins>
        <div slot="center">
          <div class="img" v-if="!ticket">
            <wxlogin
              appid="wx5ee2bd57695203ab"
              scope="snsapi_login"
              redirect_uri="http%3a%2f%2fjc.tulaway.cn%2fshop%2fpcwxlogin%2flogin"
              state="123"
            ></wxlogin>
          </div>
          <div class="img" v-if="type == '203'">
            <p>绑定您的手机号码</p>
            <verify-center
              ref="verifyCenter"
              @validateSuccess="validateSuccess"
              :isBinding="true"
            ></verify-center>
          </div>
          <div class="img" v-if="type == '202'">
            <div class="title co_333">请选择您的登录角色</div>
            <div class="register">
              <div class="supplier" @click="roleLogin(1)">
                <img class="img" src="@/assets/Login/sbg.png" />
                <div class="role co_fff">
                  <img class="icon" src="@/assets/Login/supplier.png" />
                  <span>供应商</span>
                </div>
              </div>
              <div class="purchaser" @click="roleLogin(2)">
                <img class="img" src="@/assets/Login/pbg.png" />
                <div class="role co_fff">
                  <img class="icon" src="@/assets/Login/gouwuman.png" />
                  <span>采购商</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div slot="loginbtn"></div>
        <router-link slot="icon1" :to="{ name: 'CodeLogin' }">
          <img src="@/assets/Login/verfiy.png" />
        </router-link>
        <router-link slot="icon2" :to="{ name: 'UserLogin' }">
          <img src="@/assets/Login/pwd.png" />
        </router-link>
      </logins>
    </div>
  </div>
</template>

<script>
// 微信登录
// 9、10、11位是type值
// 201：代表已绑定并且只有一个账号，202：代表已绑定但存在两个角色的账号，203：代表未绑定，进入绑定界面，204：未获取到微信用户信息
// 扫码成功跳回本页面
import logins from "@/components/login/logins.vue";
import wxlogin from "vue-wxlogin";
import VerifyCenter from "@/components/login/verifyCenter";
import { loginTicket } from "@/api/user";
export default {
  data() {
    return {
      ticket: "",
      type: "",
    };
  },
  components: { logins, wxlogin, VerifyCenter },
  name: "WeChatLogin",
  mounted() {
    let ticket = this.$route.query.ticket;
    let type = "";
    if (!this.validatenull(ticket)) {
      this.ticket = ticket;
      ticket = ticket.split("");
      type = "" + ticket[8] + ticket[9] + ticket[10];
      this.type = type;
    }
    if (type == "201") {
      this.singleLogin();
      this.$Log.primary("代表已绑定并且只有一个账号");
    } else if (type == "202") {
      this.$Log.primary("代表已绑定但存在两个角色的账号");
    } else if (type == "203") {
      this.$Log.primary("代表未绑定，进入绑定界面");
    } else if (type == "204") {
      this.$Log.primary("未获取到微信用户信息");
    }
  },
  methods: {
    // 校验成功
    validateSuccess(e) {
      let data = this.deepClone(e);
      data.ticket = this.ticket;
      this.$store.dispatch("loginTicket", data).then((res) => {
        if (res.success) {
          this.$message.success("登陆成功！");
          this.$router.push({
            path: "/",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 角色登录
    roleLogin(type) {
      this.$store
        .dispatch("loginTicket", {
          ticket: this.ticket,
          roleType: type,
        })
        .then((res) => {
          if (res.success) {
            this.$message.success("登陆成功！");
            this.$router.push({
              path: "/",
            });
          } else {
            this.$message.error(res.msg);
            this.type = "";
            this.ticket = "";
          }
        });
    },
    // 单角色登录
    singleLogin() {
      this.$store
        .dispatch("loginTicket", {
          ticket: this.ticket,
        })
        .then((res) => {
          if (res.success) {
            this.$message.success("登陆成功！");
            this.$router.push({
              path: "/",
            });
          } else {
            this.$message.error(res.msg);
            this.type = "";
            this.ticket = "";
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  height: 100vh;
  background: url("../../assets/Login/pbig.png") no-repeat center center;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .img {
    margin-bottom: 10px;
    .code {
      height: 150px;
      width: 150px;
    }
  }
}
.register {
  .purchaser,
  .supplier {
    display: inline-block;
    position: relative;
    cursor: pointer;
    .img {
      width: 175px;
    }
    .role {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      /* text-align: center; */
      .icon {
        width: 40px;
        display: inline-block;
      }
      span {
        font-size: 14px;
        display: block;
      }
    }
  }
  .purchaser {
    margin-left: 20px;
  }
}
.title {
  margin-bottom: 30px;
}
</style>